// https://next-intl-docs.vercel.app/docs/getting-started/app-router/with-i18n-routing
import { notFound } from 'next/navigation';
import { getRequestConfig } from 'next-intl/server';
// import path from 'path';
// Can be imported from a shared config
export const locales = ['en', 'zh'];

// console.log('path', path.resolve(__dirname));
export default getRequestConfig(async (props) => {
  console.log('getRequestConfig', props.locale);
  const { locale = 'en' } = props;
  // Validate that the incoming `locale` parameter is valid
  if (!locales.includes(locale as any)) notFound();

  const home = await import(`../public/locales/${locale}/home.json`);
  const common = await import(`../public/locales/${locale}/common.json`);
  const game = await import(`../public/locales/${locale}/game.json`);
  const task = await import(`../public/locales/${locale}/task.json`);
  const friend = await import(`../public/locales/${locale}/friend.json`);
  const rankings = await import(`../public/locales/${locale}/rankings.json`);
  const store = await import(`../public/locales/${locale}/store.json`);
  const settings = await import(`../public/locales/${locale}/settings.json`);

  return {
    messages: {
      ...home,
      ...common,
      ...game,
      ...task,
      ...friend,
      ...rankings,
      ...store,
      ...settings,
    },
  };
});
