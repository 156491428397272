import { getPublicAsset } from '@/utils/getPublicAsset';
import { store } from '@/store';
import { enableMusicAtom } from '@/store/settings';

// Init BGM
const BGM = getPublicAsset('/audios/sunshine-adventure.mp3');
export const AudioElement = new Audio(BGM);
AudioElement.loop = true;

export const initBGM = () => {
  const handleDocumentClick = () => {
    if (store.get(enableMusicAtom) && AudioElement.paused) {
      AudioElement.play();
    }
    document.removeEventListener('click', handleDocumentClick);
  };
  document.addEventListener('click', handleDocumentClick);
};

export const handleCanPlayThrough = (enableMusicEffects: boolean) => {
  if (enableMusicEffects) {
    AudioElement.play();
  } else {
    AudioElement.pause();
  }
};

// listen to the canplaythrough event
AudioElement.addEventListener('canplaythrough', () => handleCanPlayThrough(store.get(enableMusicAtom)));