import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

export function initializeAnalytics() {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 1,
  });
  amplitude.add(sessionReplayTracking);

  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, {
    autocapture: true,
  });
}

export function trackEvent(
  eventName: string,
  eventProperties?: Record<string, any>,
) {
  amplitude.track(eventName, eventProperties);
}

export function setUserProperties(properties: {
  tgId: string;
  name: string;
  gold: string;
  registerCountry: string;
  bananaCount: string;
  bananaOnlineId: string;
  claimEasterEgg: boolean;
}) {
  const identifyEvent = new amplitude.Identify();
  Object.entries(properties).forEach(([key, value]) => {
    identifyEvent.set(key, value);
  });
  amplitude.identify(identifyEvent);

  amplitude.setUserId(
    `${properties.name}-${properties.tgId}-${properties.registerCountry}`,
  );
}
